import * as React from "react";
import { ThemeProvider } from 'styled-components';
import { Box } from 'rebass/styled-components';
import theme from '../theme';
import SEO from "./seo";

import "normalize.css"

const Layout = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Box
      sx={{
        color: "primary",
        fontSize: 2,
        fontFamily: "body",
        "h1": {
          fontSize: 6,
        },
        "h2": {
          fontSize: 5,
          fontWeight: "headingLight",
        },
        "h3": {
          fontSize: 3,
        }
      }}>
      {children}
      </Box>
    </ThemeProvider>
  )
}

export default Layout;