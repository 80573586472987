import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import styled, { withTheme } from "styled-components";
const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;

  #contact-map-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    #contact-map {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .mapboxgl-control-container {
    display: none;
  }
`;

const Map = ({ theme }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(false);
  const mapSettings = {
    apiKey:
      "pk.eyJ1Ijoia2Fpcm9zLW5jIiwiYSI6ImNrZ2E3eTkyMjA0Z2syeXF1dzVqbjd1Z2oifQ.oal7C84Rew6dLPxaUZFJEA",
    map: {
      center: [166.4602776, -22.2546401],
      offset: [0, 0], //h,v
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 14,
      scrollZoom: false,
      interactive: false,
    },
  };
  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const mapboxgl = require("!mapbox-gl");
      mapboxgl.accessToken = mapSettings.apiKey;
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        ...mapSettings.map,
      });
      map.flyTo({
        center: mapSettings.map.center,
        zoom: mapSettings.map.zoom,
        offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
        maxDuration: 1,
      });
      new mapboxgl.Marker({
        color: theme.colors.primary,
      })
        .setLngLat(mapSettings.map.center)
        .addTo(map);
      window.onresize = function () {
        map.flyTo({
          center: mapSettings.map.center,
          zoom: mapSettings.map.zoom,
          offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
          maxDuration: 1,
        });
      };
      map.on("load", () => {
        setMap(map);
        map.resize();
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  return (
    <MapWrapper>
      <div id="contact-map-wrapper">
        <div ref={(el) => (mapContainer.current = el)} id="contact-map" />
      </div>
    </MapWrapper>
  );
};

export default withTheme(Map);

// Hook
function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
