import PropTypes from "prop-types";
import React from "react";
import loadable from "@loadable/component";
import { Box } from 'rebass/styled-components';

const Icon = ({ icon, size, useCurrentColor, ...props }) => {
  const SvgItem = loadable(() => import(`../../images/icons/${icon}.svg`));
  const svgprops = {};
  if (useCurrentColor) {
    svgprops.fill = "currentColor";
    svgprops.stroke = "transparent";
  }
  return (
  <SvgItem height={size + "px"} width={size + "px"} {...svgprops} {...props} />
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  useCurrentColor: PropTypes.bool
};
Icon.defaultProps = {
  size: 24,
  icon: "home",
  useCurrentColor: true
};

export default Icon;
