import * as React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
import { Flex, Box, Heading, Text, Link } from "rebass/styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Icon from "../components/UI/Icon";
import Map from "../components/Map";

const IndexPage = ({ data }) => {
  const content = data.contentJson;
  return (
    <Layout>
      <Box
        id="header"
        backgroundColor="#000"
        
      >
        <Box
          variant="container"
          sx={{
            overflowX: "hidden",
          }}
        >
          <Flex 
            height={["70px", "80px", "120px", "120px"]}
            alignItems={["center","center","center","center"]}
            justifyContent={["flex-start", "flex-start", "center", "center"]}
            flexDirection="row"
            sx={{'img':{height:["50px", "70px", "100px", "100px"], width:"auto !important"}}}
          >
            <GatsbyImage layout="constrained" image={getImage(content.logo)} alt={content.company} />
          </Flex>
        </Box>
      </Box>

      <Box
        variant="container"
        
        sx={{
          overflowX: "hidden",
        }}
      >
        
        <Flex pt={3} alignItems="center" flexDirection="column">
          <GatsbyImage image={getImage(content.banner)} alt="Bannière" />
        </Flex>

        {content.intro && (
          <Box id="intro" mt={7} textAlign="center" sx={{ lineHeight: "1.5" }}>
            <Heading as="h1" mb={3}>
              {parse(content.intro.title)}
            </Heading>
            <Text px={[1, 3, 8]}>{parse(content.intro.text)}</Text>
          </Box>
        )}

        {content.intro && (
          <Box 
            id="cards"
            mt={7}
            sx={{
              'display': "grid",
              'grid-template-columns': ["repeat(1, 1fr)","repeat(1, 1fr)","repeat(1, 1fr)","repeat(3, 1fr)"],
              'grid-gap': ["40px","40px","40px","15px"]
            }}
          >
            {content.cards.map((card, i) => {
              return (
                <Box
                  key={i}
                  
                  
                >
                  <Flex justifyContent="center">
                    <GatsbyImage
                      image={getImage(card.image)}
                      alt={card.title}
                    />
                  </Flex>
                  <Heading as="h3" variant="cardTitle">
                    {parse(card.title)}
                  </Heading>
                  <Text textAlign="center" px={1} fontSize={[1, 2]}>
                    {parse(card.text)}
                  </Text>
                </Box>
              );
            })}
          </Box>
        )}
        <Flex
          id="timap"
          mt={8}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box width={[1, 1, 1, 1 / 2]}>
            <Map />
          </Box>
          {content.opentime && (
            <Flex
              id="opentime"
              mt={[8, 8, 8, 0]}
              justifyContent="center"
              width={[1, 1, 1, 1 / 2]}
            >
              <Box>
                <Flex alignItems="center" justifyContent="left" ml={3} mb={3}>
                  <Heading as="h2" ml="1" fontWeight="800 !important">
                    {content.company}
                  </Heading>
                </Flex>
                <Box>
                  <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
                    {content.contact.items.map((item, i) => {
                      return (
                        item.inFooter && (
                          <Flex
                            key={i}
                            mt={3}
                            mx={4}
                            justifyContent="left"
                            flexWrap="wrap"
                            flexDirection="column"
                          >
                            {item.url && (
                              <Flex
                                justifyContent="left"
                                flexWrap="wrap"
                                flexDirection="row"
                                sx={{
                                  a: {
                                    display: "flex",
                                    color: "#000",
                                  },
                                }}
                              >
                                <Link href={item.url} width={[1]}>
                                  <Box mr={1}>
                                    <Icon icon={item.icon} />
                                  </Box>
                                  <Text pt="2px">{parse(item.text)}</Text>
                                </Link>
                              </Flex>
                            )}
                            {!item.url && (
                              <Flex>
                                <Box mr={1}>
                                  <Icon icon={item.icon} />
                                </Box>
                                <Text pt="2px">{parse(item.text)}</Text>
                              </Flex>
                            )}
                          </Flex>
                        )
                      );
                    })}

                  </Flex>
                  <Flex alignItems="center" ml={4} mt={4} justifyContent="left" mb={3}>
                    <Box mr={1}>
                      <Icon icon="clock" />
                    </Box>
                    <Text ml={1} pt="2px">
                      {content.opentime.title}
                    </Text>
                  </Flex>
                  <Flex
                    mx={4}
                    justifyContent="center"
                    flexWrap="wrap"
                    sx={{
                      border: "none",
                      borderColor: "border",
                      lineHeight: "1",
                    }}
                  >
                    <Box display="inline-block">
                      {content.opentime.days.map((day, i) => {
                        return (
                          <Flex
                            key={i}
                            sx={{
                              border: "none",
                              borderColor: "border",
                              borderTopWidth: "0",
                              "&:first-child": {
                                borderTopWidth: "1px",
                              },
                            }}
                          >
                            <Box
                              width={["120px"]}
                              p={1}
                              sx={{
                                borderRight: "none",
                                borderColor: "border",
                              }}
                            >
                              <Text>{day.day}</Text>
                            </Box>
                            <Box p={1}>
                              <Text>{day.text}</Text>
                            </Box>
                          </Flex>
                        );
                      })}
                    </Box>
                  </Flex>
                </Box>
                
              </Box>
            </Flex>
          )}
        </Flex>

        {content.links && (
          <Flex id="links" mt={8} justifyContent="center">
            <Box>
              <Flex alignItems="center" justifyContent="center" mb={3}>
                <Icon icon="link" />
                <Heading as="h3" ml="1">
                  {content.links.title}
                </Heading>
              </Flex>
              <Box>
                {content.links.urls.map((url, i) => {
                  return (
                    <Box
                      key={i}
                      textAlign="center"
                      mb={1}
                      sx={{
                        a: {
                          color: "primary",
                          "&:hover": {
                            color: "secondary",
                          },
                        },
                      }}
                    >
                      <Link href={url.url}>{url.text}</Link>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Flex>
        )}
      </Box>
      <Box
          id="stickyBar"
          sx={{
            position: "fixed",
            top: [0, 0, "50%", "50%"],
            /*left: ["inherit", "inherit", "inherit", "inherit"],*/
            right: ["10px", "15px", 0, 0],
            transform: [
              "translateY(0)",
              "translateY(0)",
              "translateY(-50%)",
              "translateY(-50%)",
            ],
          }}
      >
        <Flex flexDirection={["row", "row", "column", "column"]}>
          {content.contact.items.map((item, i) => {
            return (
              item.inStickyBar && (
                <Box key={i}>
                  {item.url && (
                    <Box
                      className={item.type}
                      sx={{
                        a: {
                          display: "flex",
                          alignItems: "center",
                          p: [1, 1, 1, 2],
                          mx: [1, 1, 1, 0],
                          my: [0, 0, 1, 1],
                          boxShadow: "base",
                          borderRadius: [
                            "0px 0px 5px 5px",
                            "0px 0px 5px 5px",
                            "5px 0 0 5px",
                            "5px 0 0 5px",
                          ],
                          bg: "white",
                          color: "primary",
                          transition: "all ease 200ms",
                          svg: {
                            width: ["24px", "30px", "40px"],
                            height: ["24px", "30px", "30px", "40px"],
                          },
                          "&:hover": {
                            pt: ["12px", "12px", "12px", 2],
                            ml: [1, 1, 1, "-2px"],
                          },
                        },
                        "&.facebook": {
                          a: {
                            bg: "facebook",
                            color: "white",
                          },
                        }
                      }}
                    >
                      <a href={item.url} target={item.url.startsWith('http')?"_blank":"self"}>
                        <Icon icon={item.icon} />
                      </a>
                    </Box>
                  )}
                </Box>
              )
            );
          })}
        </Flex>
      </Box>
      <Box
          id="footer"
          mt={8}
          py={7}
          bg="footer"
          color="white"
          sx={{
            position: "relative",
            
            "& > *": {
              position: "relative",
              zIndex: 2,
              a: {
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  color: "white",
                  textDecoration: "underline"
                },
              },
            },
          }}
        >
          <Heading as="h3" variant="h3" textAlign="center" mb={1}>
            {content.contact.title}
          </Heading>
          <Flex justifyContent="center" alignItems="center" flexDirection={["column","column","column","row"]} flexWrap="wrap">
            {content.contact.items.map((item, i) => {
              return (
                item.inFooter && (
                  <Box key={i} mt={3} mx={4}>
                    {item.url && (
                      <Box
                        sx={{
                          a: {
                            display: "flex",
                          },
                        }}
                      >
                        <Link href={item.url}>
                          <Box mr={1}>
                            <Icon icon={item.icon} />
                          </Box>
                          <Text pt="2px">{parse(item.text)}</Text>
                        </Link>
                      </Box>
                    )}
                    {!item.url && (
                      <Flex>
                        <Box mr={1}>
                          <Icon icon={item.icon} />
                        </Box>
                        <Text pt="2px">{parse(item.text)}</Text>
                      </Flex>
                    )}
                  </Box>
                )
              );
            })}
          </Flex>
        </Box>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    contentJson {
      logo {
        childImageSharp {
          gatsbyImageData(
            height: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      company
      banner {
        childImageSharp {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      intro {
        text
        title
      }
      cards {
        title
        text
        image {
          childImageSharp {
            gatsbyImageData(
              width: 600
              height: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      opentime {
        title
        days {
          day
          text
        }
      }
      contact {
        title
        items {
          inFooter
          inStickyBar
          text
          url
          icon
          type
        }
      }
    }
  }
`;

export default IndexPage;
