const theme = {
  breakpoints: ['576px','768px','992px','1200px'],
  fontSizes: [
    14, 16, 18, 20, 24, 28, 32, 48, 64
  ],
  space: [
    0, 10, 15, 20, 30, 40, 50, 60, 90
  ],
  colors: {
    background: 'white',
    primary: '#000',
    secondary: '#2DA4D8',
    border: "#CACACA",
    footer: "#000",
    facebook: "#3b5998"
  },
  fonts: {
    fontName: 'Montserrat',
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    headingLight: 500,
    heading: 700,
  },
  shadows: {
    base: "0 5px 5px rgba(0, 0, 0, 0.15)"
  },
  text: {
    cardTitle: {
      textAlign: "center",
      fontWeight: "headingLight",
      mx:0,
      my:1,
      py:2
    }
  },
  variants: {
    container: {
      width: '100%',
      maxWidth: ["100%", "100%", "1260px"],
      m: "0 auto",
      px: [2,4]
    },
    grid: {
      flexWrap: "wrap",
      ml: [-2,-4]
    },
    gridItem: {
      pl: [2,4],
      "&:last-child": {
        mb: [0,0,0]
      }
    },
  },
  buttons: {}
}

export default theme;